import { ThemeConfig, theme } from "antd";

export { GlobalStyle } from "../../uikit/theme";

// const blue = "#0663C0";
const blue = "#1B68E4";
const red = "#CE0033";
const orange = "#F46C10";
const green = "#0D7A23";
const yellow = "#FFD007";
const purple = "#504DC1";

const baseThemeConfig: ThemeConfig = {
    hashed: false,
    cssVar: {
        key: "sol",
    },
    token: {
        blue,
        red,
        orange,
        green,
        yellow,
        purple,

        colorPrimary: purple,
        colorInfo: purple,
        colorSuccess: green,
        colorError: red,
        colorWarning: orange,
        colorTextPlaceholder: "#1A1A1AA6",
        fontSize: 14,
        fontFamily: "'Nunito Sans', 'sans-serif'",
        borderRadius: 8,
        borderRadiusXS: 2,
        borderRadiusSM: 4,
    },
};

const baseToken = theme.getDesignToken(baseThemeConfig);

export const themeConfig: ThemeConfig = {
    ...baseThemeConfig,
    components: {
        ...baseThemeConfig.components,
        Menu: {
            ...baseThemeConfig.components?.Menu,
            itemMarginInline: baseToken.marginXS,
            itemMarginBlock: baseToken.marginXS,
            itemHeight: 32,
            subMenuItemBg: baseToken.colorWhite,
            iconMarginInlineEnd: baseToken.paddingXS,
            iconSize: 24,
        },
        Layout: {
            headerBg: "var(--ant-layout-header-bg)",
            footerBg: "var(--ant-color-white)",
        },
        Button: {
            controlHeightLG: 48,
        },
        Input: {
            controlHeightLG: 48,
        },
        Select: {
            singleItemHeightLG: 48,
        },
    },
};
