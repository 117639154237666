import { AxiosPromise, AxiosRequestConfig } from "axios";
import objectHash from "object-hash";
import { useCallback, useMemo } from "react";
import {
    MutationConfig as UseMutationOptions,
    UseQueryObjectConfig as UseQueryOptions,
    useMutation,
    useQuery,
} from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

export enum OrderingDirection {
    /** Ascending */
    ASC = "asc",
    /** Descending */
    DESC = "desc",
}

export interface IPaginateable {
    pagination?: false | { page: number; perPage: number };
}
export interface IOrderable<T> {
    order?: { by: T; direction: OrderingDirection };
}

export function mapOrder(order: IOrderable<unknown>["order"]) {
    return order && { [`order[${order.by}]`]: order.direction };
}

export function mapPagination(pagination: IPaginateable["pagination"]) {
    // Disable pagination on the query if not set
    return !pagination ? { pagination: false } : pagination;
}

export const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export interface IApiQueryFunction<T> {
    (data: T): AxiosRequestConfig | undefined;
}

export function createApiQueryHook<TQueryParameters, TQueryResult, TError = unknown>(
    queryFn: IApiQueryFunction<TQueryParameters>,
) {
    return (
        params: TQueryParameters,
        { enabled = true, ...options }: UseQueryOptions<TQueryResult, TError>["config"] = {},
    ) => {
        const api = useApi();

        const { config, hash } = useMemo(() => {
            const config = queryFn(params);

            return {
                config,
                hash: config ? objectHash(config) : null,
            };
        }, [params]);

        const canEnable = !!config; // Only enable if there is a config

        // Here we force typing to always be defined as we guarantee the fact that no calls will be emitted from react-query in this case
        const query = useCallback(
            async () => unwrapAPIResult(api.request<TQueryResult>(config as AxiosRequestConfig)),
            [api, hash],
        );

        return useQuery<TQueryResult, TError>({
            config: {
                ...options,
                enabled: canEnable && enabled,
            },
            queryFn: query,
            queryKey: [config],
        });
    };
}

export interface IApiMutationFunction<T> {
    (data: T): AxiosRequestConfig;
}

export function createApiMutationHook<TQueryParameters, TQueryResult, TError = unknown>(
    mutationFn: IApiMutationFunction<TQueryParameters>,
) {
    return (options: UseMutationOptions<TQueryResult, TError, TQueryParameters> = {}) => {
        const api = useApi();

        // Here we force typing to always be defined as we guarantee the fact that no calls will be emitted from react-query in this case
        const mutation = useCallback(
            async (params: TQueryParameters) => unwrapAPIResult(api.request<TQueryResult>(mutationFn(params))),
            [api],
        );

        return useMutation(mutation, options);
    };
}
