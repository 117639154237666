import Router from "next/router";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Route } from "@sol/routing";

import useAuth from "../hooks/useAuth";

type Props = {
    status: number;
    className?: string;
};

const HttpErrorDisplay = ({ status, className }: Props) => {
    const [t] = useTranslation();
    const { logout } = useAuth();

    const message = useMemo(() => {
        switch (status) {
            case 400:
                return t("errors.badRequest");
            case 404:
                return t("errors.notFound");
            case 405:
                return t("errors.notAllowed");
            case 500:
                return t("errors.internalServerError");
            default:
                return t("errors.default");
        }
    }, [status]);

    if (status === 401) {
        logout();
        Router.push(Route.LOGIN);
        return null;
    }

    return <div className={className}>{message}</div>;
};

export default styled(HttpErrorDisplay)`
    display: fllex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;
