import { INotificationResource, NotificationStatus } from "@sol/sdk";
import { createApiMutationHook, IApiMutationFunction } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type INotificationMutationParameters = {
    notification: RelationalValue<INotificationResource>;
    status: NotificationStatus;
};

export type INotificationMutationResult = INotificationResource;

export const notificationEditMutation: IApiMutationFunction<INotificationMutationParameters> = ({
    notification,
    status,
}) => ({
    method: "PUT",
    url: `/notifications/${mapRelationalValue(notification)}`,
    data: {
        status,
    },
});

export const useNotificationEditMutation = createApiMutationHook<
    INotificationMutationParameters,
    INotificationMutationResult
>(notificationEditMutation);
